import axios from "axios";

import {useState, useEffect} from 'react'
import { useNavigate , useLocation} from 'react-router-dom';
import {APIURL} from './config'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

import Navbar from './Navbar'
import Topbar from './Topbar'

import Status from './Status'

function MudancaStatus() {

    const navigate = useNavigate()

    const locate = useLocation()

    const myoportunidade = locate.state.codigooportunidade
   

    const [status, setStatus] = useState()
    const [datamudanca, setDatamudanca] = useState(new Date())
    

    useEffect(() => {
        if (!sessionStorage.getItem("codigoempresa"))  {
            navigate('/login')
        }

    }, [])

    

    const  doSend = async (e) => {
        e.preventDefault()
        if (!status) {
            alert("Faltam dados!")
            return
        }
        try {
            const res = await axios.post(`${APIURL}/mudastatus.php`, {oportunidadeid:  myoportunidade , statusid : status, datamudanca : formatDate(datamudanca)})
            if (res.data[0].ok) {
                navigate(-1)
            } else {
                if (res.data[0].msg == 3) {
                    alert(res.data[0].msg + "Erro ao mudar status!")
                }
            }
        }
        catch(err){
            alert(err + " mudar status")
        }

    }


    function formatDate(date) {
        const d = new Date(date);
        return [
            d.getFullYear(),
            d.getMonth() + 1,
            d.getDate(),

        ].join('-');
    }



  return (
    <div >
        <Navbar />
        <div >
            <div className="white-bg" style={{height: '100%', padding: 30, }}>
                <div className="container" >
                    <div className="row mb-4 " >
                        <div className="col-12" >
                            <h1>Mudar Status</h1>
                        </div>
                    </div>

                    <div className="row mb-4 " >
                        <div className="col-12" >

                            <div className="form-group">
                                <label>Novo Status</label> 
                                <Status selectstatus={(num) => setStatus(num)}  />
                            </div>

                            <div className="form-group">
                                <label style={{display:"block"}}>Data</label> 
                                <DatePicker  dateFormat="dd/MM/yyyy" style={{display:"block"}} className="form-control" selected={datamudanca} onChange={(datamudanca) => setDatamudanca(datamudanca)} />
                            </div>

                            

                            <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'space-between', marginTop: 80}}>

                                <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                    <button onClick={() => navigate(-1)} style={{marginRight: 20}} className="btn btn-lg btn-primary btn-outline pull-right m-t-n-xs" ><strong>Voltar</strong></button>
                                    <button onClick={(e)=> doSend(e)} className="btn btn-lg btn-primary pull-right m-t-n-xs" type="submit"><strong>Salvar</strong></button>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  );
}

export default MudancaStatus;