import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'

import {useState, useEffect} from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {APIURL} from './config'

function Revendas() {

    const [results, setResults] = useState([])
    const [origem, setOrigem] = useState()
    const [classificacao, setClassificacao] = useState()
    const [tipo, setTipo] = useState()
    const [listaclassificacao, setListaclassificacao] = useState('')
    const [listatipo, setListatipo] = useState('')
    const [status, setStatus] = useState()
    const [texto, setTexto] = useState()
    const [mydisplay, setMydisplay] = useState('none') 

    const navigate = useNavigate()
    const locate = useLocation()

    let mycodigoempresapai

    mycodigoempresapai = sessionStorage.getItem("codigoempresa")
    

    useEffect(() => {
        if (!sessionStorage.getItem("codigoempresa")) {
            navigate('/login')
        }
        listresume()
      
    }, [])

    const listresume= async () => {
        
        try {
            const res = await axios.post(`${APIURL}/listrevendas.php`, {codigoempresa : mycodigoempresapai});
           if (res.data[0].ok) {
                    setResults(res.data[0].conjuntofinal);
            } else {
                alert("Erro na listagem de revendas")
            }
            
        } catch (err) {
            alert(err + " lista de revendas");
        }
    }  

    
    return (

        <div id="" className="">

           <Navbar />

            <div >

                
                <div className="white-bg" style={{minHeight: '100%', padding: 30}}>
                    <div className="container" >
                        <div className="row d-flex justify-content-between mb-3">
                            <div className="col-12">
                                <h1>{sessionStorage.getItem("nomeempresa")} - Revendas</h1>
                            </div>
                        </div>

                        <div className="row ">
                        <div className="col-12">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Empresa</th>
                                            <th>Oportunidades</th>
                                           </tr>
                                    </thead>

                                    <tbody>
                                
                                        {results &&  results.map((item, i) => {

                                            
                                            return(
                                                <tr key={item.empresaid}>
                                                    <td>{item.empresaid}</td>
                                                    <td><Link to="/revendaunica" state={{codigoempresa: item.empresaid}}><p>{item.nomeempresa}</p></Link></td>
                                                    
                                                    <td><Link to="/oportunidades" state={{codigoempresapai: item.empresaid}}><button className="btn btn-xs btn-outline fundoverde corbranco" ><i className=" fa fa-dollar"></i> </button></Link></td>
                                                    
                                                    {/* <td><Link to="/novaoportunidade" state={{codigoempresapai: item.empresaid}}><button className="btn btn-xs btn-outline  fundoverde corbranco" ><i className=" fa fa-plus-circle"></i></button></Link></td> */}
                                                </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <Link to="/novarevenda"><button className="btn btn-xs fundoverde corbranco" style={{marginTop: 10, alignItems: 'center', padding:5}}><i className=" fa fa-plus-circle"></i> Nova Revenda </button></Link>
                    </div>

                </div>
                
            </div>

        </div>
      );
    }
    
    export default Revendas;
