import { Link } from "react-router-dom";
import logo from './img/logo-horizontal-4vendor.png'

function Navbar() {

    return(

        <nav className="navbar navbar-expand-lg navbar-light bg-light mb-5 pl-md-5">

                <a className="navbar-brand" href="#"><img src={logo} alt="logo" className="img-fluid" style={{width:150}} /></a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mynavbar" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                        

                <div className="collapse navbar-collapse" id="mynavbar">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item pl-md-5 pr-2 mb-2 "><Link className="nav-link"  to="/home"><i className="fa fa-home"></i> Home</Link></li>
                        {((sessionStorage.getItem("tiposeller") == 1) || (sessionStorage.getItem("tiposeller") == 2))  &&
                            <li className="nav-item  pr-2 mb-2"><Link  className="nav-link" to="/revendas"><i className="fa fa-lightbulb-o"></i> Revendas</Link></li>
                        }
                        <li className="nav-item pr-2 mb-2"><Link  className="nav-link" to="/oportunidades"><i className="fa fa-list"></i> Oportunidades</Link></li>
                        <li className="nav-item pr-2 mb-2"><Link  className="nav-link" to="/apoio"><i className="fa fa-briefcase"></i> Apoio</Link></li>
                        <li className="nav-item pr-2 mb-2"><Link  className="nav-link" to="/logout"><i className="fa fa-sign-out"></i> Log out </Link></li>
                    </ul>
                </div>
            

        </nav>

        
    )
}

export default Navbar