import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'

import {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import {APIURL} from './config'

function Home() {

    const [results, setResults] = useState([])

    const navigate = useNavigate()

    const mycodigoempresa = sessionStorage.getItem("codigoempresa")

    useEffect(() => {
        if (!sessionStorage.getItem("codigoempresa")) {
            navigate('/login')
        }
        listresume( mycodigoempresa)
    }, [])

    const listresume= async (mytoken) => {
        
        try {
            const res = await axios.post(`${APIURL}/getestatisticas.php`, {codigoempresa: mycodigoempresa});
            if (res.data[0].ok) {
                setResults(res.data[0].conjuntofinal);
            } else {
                alert(res.data[0].msg)
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

    return (

        <div className="">
            <Navbar />
            <div className="container">

                <div className="row" >
                    <div className="col-12">
                        <h1>Home</h1>
                    </div>
                </div>


            <div className="space40"></div>

            <div className="container">
                <div className="row" >
                    
                    <div className="col-md-3 caixaverde d-flex justify-content-between p-3 mb-3">
                            <div >
                                <i className="fa fa-user fa-5x corbranco"></i>
                            </div>
                            <div className="text-right corbranco mr-2" >
                                <Link to="/revendas"><h5 className="corbranco">REVENDAS </h5></Link>
                                <h2>{results.numrevendas}</h2>
                            </div>
                    </div>
                

                    <div className="col-md-1 d-none d-sm-block "></div>

                    <div className="col-md-3 caixaverde d-flex justify-content-between p-3 mb-3" >
                        <div >
                            <i className="fa fa-money fa-5x corbranco pr-2"></i>
                        </div>
                        <div className="text-right corbranco" >
                            <Link to="/oportunidades"><h5 className="corbranco">OPORTUNIDADES </h5></Link>
                            <h2>{results.numoportunidades}</h2>
                        </div>
                    </div>

                    <div className="col-md-1 "></div>

                    <div className="col-md-3 caixaverde d-flex justify-content-between p-3 mb-3" >
                        <div >
                            <i className="fa fa-lightbulb-o fa-5x corbranco mr-2"></i>
                        </div>
                        <div className="text-right corbranco" >
                            <h5>OPORTUNIDADES DAS REVENDAS </h5>
                            <h2>{results.numoportunidadesrevendas}</h2>
                        </div>
                    </div>

                    <div className="col-md-1"></div>

                    
                    
                </div>

                <div style={{marginRight: 30,position: 'absolute', right:40,  bottom: 40, }}>
                    <Link to="/novaoportunidade"> <i className="fa fa-plus-circle fa-5x" style={{color:'orange'}}></i></Link>
                </div>
            </div>
            </div>
            </div>
          


      );
    }
    
    export default Home;
