import axios from "axios";

import {useState, useEffect} from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {APIURL} from './config'

import Navbar from './Navbar'
import Topbar from './Topbar'
import Status from './Status'
import Produtos from './Produtos'


function NovaOportunidade() {

    const navigate = useNavigate()
    const locate = useLocation()

    const [displaycampos, setDisplaycampos] = useState("none")

    let mycodigoempresapai

    if (locate.state) {
        mycodigoempresapai = locate.state.codigoempresapai
    } else {
        mycodigoempresapai = sessionStorage.getItem("codigoempresa")
    }
    
    const [results, setResults] = useState()

    const [nomeempresa, setNome] = useState()
    const [cnpj, setCNPJ] = useState()
    const [responsavel, setResponsavel] = useState()
    const [email, setEmail] = useState()
    const [telefone, setTelefone] = useState()
    const [depto, setDepto] = useState()
    const [status, setStatus] = useState()
    const [obs, setObs] = useState()
    const [produto, setProduto] = useState()
    const [quantidade, setQuantidade] = useState()
    const [precounitario, setPrecounitario] = useState(0)

    useEffect(() => {
        if (!sessionStorage.getItem("codigoempresa"))  {
            navigate('/login')
        }
        getdadosempresa()
    }, [])

    const  pesquisarCNPJ = async (e) => {
        e.preventDefault()
        const mycnpj = cnpj.replace(/[^0-9]/g,'')
        if (mycnpj.length != 14) {
            alert("CNPJ precisa ter 14 dígitos!")
            return
        }
        setCNPJ(mycnpj)
        try {
            const res = await axios.post(`${APIURL}/getCNPJ.php`, {cnpj: mycnpj})
            if (res.data[0].ok) {
                //alert("Empresa não cadastrada!")
                setDisplaycampos("block")
            } else {
                if (res.data[0].msg == 2) {
                    if (res.data[0].cliente ==1) {

                        alert("Empresa " + res.data[0].nomeempresa + " já cadastrada como Cliente!")
                        
                    } else {
                        if (res.data[0].seller ==1) {
                            if (window.confirm("Empresa " + res.data[0].nomeempresa + " já cadastrada como Revenda! Quer cadastrá-la tambem como cliente?")) {
                                //marcacomorevenda()
                            }
                        } else {
                            alert("Empresa cadastrada sem classificação")
                        }
                    }
                } else {
                    alert("Erro na pesquisa do CNPJ")
                }
            }
        }
        catch(err){
            alert(err)
        }

    }

    const getdadosempresa= async () => {
        
        try {
           const res = await axios.post(`${APIURL}/getempresa.php`, {codigoempresa : mycodigoempresapai});
           if (res.data[0].ok) {
           
                setResults(res.data[0].conjuntofinal[0]);
            } else {
                if (res.data[0].msg == 3) {
                    alert("Erro na listagem de dados da empresa " + res.data[0].msg)
                }
            }
            
        } catch (err) {
            alert(err + " lista de dados da empresa");
        }
    }  

    const  doSend = async (e) => {
        e.preventDefault()
        let myprecounitario
        if (precounitario ) {
            myprecounitario = precounitario.replace(",",".")
        } else {
            myprecounitario = 0
        }
        try {
             
            if (!nomeempresa || !cnpj || !responsavel || !email || !telefone || !depto || !produto || !status || !quantidade) {
                alert("Faltam dados!")
                return
            }
            const res = await axios.post(`${APIURL}/insertoportunidade.php`, {empresapaiid:  mycodigoempresapai , nomeempresa : nomeempresa, cnpj: cnpj, responsavel: responsavel, email: email, telefone: telefone, depto: depto, produtoid: produto, quantidade: quantidade, precounitario: myprecounitario, status: status, datastatus: formatDate(new Date()), obs: obs})
            if (res.data[0].ok) {
                alert("Oportunidade inserida com sucesso!")
                navigate(-1)
            } else {
                if (res.data[0].msg == 3) {
                    alert("Erro ao inserir oportunidade!")
                }
            }
        }
        catch(err){
            alert(err + " oportunidade ")
        }

    }

    function formatDate(date) {
        const d = new Date(date);
        return [
            d.getFullYear(),
            d.getMonth() + 1,
            d.getDate(),

        ].join('-');
    }

    function gotoselectstatus(num) {
        setStatus(num)
    }

    function gotoselectproduto(num) {
        setProduto(num)
    }


  return (
    <div >
        <Navbar />
        <div >
            
            <div className="white-bg" style={{height: '100%', padding: 30, }}>
                <div className="container" >
                    <div className="row " >
                        <div className="col-12 mb-4" >
                            <h1>{results && results.nomeempresa} - Nova Oportunidade</h1>
                        </div>

                        <div className="col-12" >
                            <div className="form-group">
                                    <label>CNPJ</label> 
                                    <div className="d-flex justify-content-between" >
                                        <input  type='text' className="form-control" onChange={(e)=> setCNPJ(e.target.value)} style={{marginRight: 20}}/>
                                        <button onClick={(e)=>pesquisarCNPJ(e)} className="btn btn-sm fundoverde corbranco pull-right m-t-n-xs" ><strong>Pesquisar</strong></button>
                                    </div>
                            </div>

                            <div style={{display: displaycampos}} >

                                <div className="form-group">
                                    <label>Nome da Empresa (*)</label> 
                                    <input   type="text"  className="form-control" onChange={(e)=> setNome(e.target.value)}/>
                                </div>

                    
                                <div className="form-group">
                                        <label>Nome do Contato Principal (*)</label> 
                                        <input  type="text"  className="form-control" onChange={(e)=> setResponsavel(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                        <label>Email do Contato Principal (*)</label> 
                                        <input  type="text"  className="form-control" onChange={(e)=> setEmail(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                        <label>Telefone do Contato Principal (*)</label> 
                                        <input  type="text"  className="form-control" onChange={(e)=> setTelefone(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                        <label>Departamento do Contato Principal (*)</label> 
                                        <input  type="text"  className="form-control" onChange={(e)=> setDepto(e.target.value)}/>
                                </div>


                                <div className="form-group" > 
                                    <label>Status (*)</label> 
                                    <Status selectstatus={gotoselectstatus} />
                                </div>
                            

                                <div className="form-group" > 
                                    <label>Produto Principal (*)</label> 
                                    <Produtos selectproduto={gotoselectproduto} />
                                </div>

                                <div className="form-group">
                                        <label>Quantidade do Produto Principal (*)</label> 
                                        <input type="text"  className="form-control" onChange={(e)=> setQuantidade(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                        <label>Preço Unitário do Produto Principal</label> 
                                        <input type="text"  className="form-control" onChange={(e)=> setPrecounitario(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                    <label>Observação</label> 
                                    <textarea  rows={6}  className="form-control" onChange={(e)=> setObs(e.target.value)}/>
                                </div>
            
                                <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'space-between', marginTop: 80}}>
                                    <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                        <button onClick={() => navigate(-1)}style={{marginRight: 20}} className="btn btn-lg  btn-outline pull-right m-t-n-xs" ><strong>Voltar</strong></button>
                                        <button onClick={(e)=> doSend(e)}className="btn btn-lg fundoverde corbranco pull-right m-t-n-xs" type="submit"><strong>Salvar</strong></button>
                                    </div>
                                </div>  

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  );
}

export default NovaOportunidade;