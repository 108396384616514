import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'

import {useState, useEffect} from 'react'
import { Link, useNavigate, useLocation} from 'react-router-dom';
import {APIURL} from './config'

function Apoio() {

    const navigate = useNavigate()
    const locate = useLocation()

    let mycodigoempresapai

    mycodigoempresapai = sessionStorage.getItem("codigoempresa")
    

    useEffect(() => {
        if (!sessionStorage.getItem("codigoempresa")) {
            navigate('/login')
        }
    }, [])

    return (

        <div>

           <Navbar />

            <div >

                
                <div className="row  white-bg" style={{minHeight: '100%', padding: 30}}>
                    <div className="container" >
                        <div className="row  mb-4 " >
                            <div className="col-12  ">
                                <h1 className="">Material de Apoio</h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Material</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr >
                                            <td><Link to="https://docs.google.com/document/d/17G8lPtufjqv4CeqTSEm7vgQyo5vU_7cBHW7oZj-2jr4/edit?usp=sharing" target="_blank"><p>Tabela de Preços</p></Link></td>
                                        </tr>
                                        <tr >
                                            <td><Link to="https://docs.google.com/document/d/1bTw12U55U2fJ1kM5kvg-I3ZFx4d8HtD5M97AezLul0A/edit?usp=sharing" target="_blank"><p>Proposta Padrão</p></Link></td>
                                        </tr>
                                        {((sessionStorage.getItem("tiposeller") == 1) || (sessionStorage.getItem("tiposeller") == 2))  &&
                                            <tr >
                                                <td><Link to="https://docs.google.com/document/d/13ZqultSnVNxvspiw5dhOv3YNqYXFdiW0WtiZjXnlXms/edit?usp=sharing" target="_blank"><p>Contrato Padrão de Revenda</p></Link></td>
                                            </tr>
                                        }
                                        <tr >
                                            <td><Link to="https://drive.google.com/file/d/1QE92TfBoDdhRQad0rEv-Uc5UMMyawKQE/view?usp=sharing" target="_blank"><p>Vídeo de Apresentação do IT2M</p></Link></td>
                                        </tr>
                                        <tr >
                                            <td><Link to="https://drive.google.com/file/d/1Q9x3jdoTnX-u2vzwQfTY96AgaKQjX9mL/view?usp=sharing" target="_blank"><p>Folder do IT2M</p></Link></td>
                                        </tr>
                                                
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
                
            </div>

        </div>
      );
    }
    
    export default Apoio;
