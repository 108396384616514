import axios from "axios";

import {useState, useEffect} from 'react'
import { Navigate, useNavigate,useLocation } from 'react-router-dom';
import {APIURL} from './config'

import Navbar from './Navbar'
import Topbar from './Topbar'
import Status from './Status'

function NovoStatusOportunidade() {

    const navigate = useNavigate()
    const locate = useLocation()

    const mycodigooportunidade = locate.state.codigooportunidade

    const [status, setStatus] = useState()
    const [datamudanca, setDatamudanca] = useState()

    useEffect(() => {
        if (!sessionStorage.getItem("codigoempresa"))  {
            navigate('/login')
        }
    }, [])

       

    const  doSend = async (e) => {
        //alert(mycodigooportunidade + status + datamudanca)
        //alert(formatStringData(datamudanca))
        e.preventDefault()
        if ( !status || !datamudanca) {
            alert("Faltam dados!")
            return
        }
        try {

            const res = await axios.post(`${APIURL}/insertstatus1.php`, {codigooportunidade:  mycodigooportunidade , status: status, datastatus : formatStringData(datamudanca)})
            if (res.data[0].ok) {
                navigate(-1)
            } else {
                alert("Erro ao inserir status!" + res.data[0].msg)
            }
        }
        catch(err){
            alert(err)
        }

    }

       
    function formatStringData(data) {
        var dia  = data.split("/")[0];
        var mes  = data.split("/")[1];
        var ano  = data.split("/")[2];
        return ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2);
    }

    function gotoselectstatus(num) {
        setStatus(num)
    }


  return (
    <div >
        <Navbar />
        <div >
            <Topbar />
            <div className="white-bg" style={{height: '100%',  padding: 30, }}>
                <div className="container" >
                    <div className="row  " >
                        <div className="col-12 mb-4" >
                            <h1>Novo Status</h1>
                        </div>
                    
                        <div className="col-12 " >
                            <div className="form-group" > 
                                <label>Status (*)</label> 
                                <Status selectstatus={gotoselectstatus} />
                            </div>

                            <div className="form-group">
                                    <label>Data da Mudança (*)</label> 
                                    <input type="text"  className="form-control" onChange={(e)=> setDatamudanca(e.target.value)}/>
                            </div>
                            

                            <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'space-between', marginTop: 80}}>

                                <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                    <button style={{marginRight: 20}} onClick={() => navigate(-1)} className="btn btn-lg  btn-outline pull-right m-t-n-xs" ><strong>Voltar</strong></button>
                                    <button onClick={(e)=> doSend(e)} className="btn btn-lg fundoverde corbranco  pull-right m-t-n-xs" type="submit"><strong>Salvar</strong></button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default NovoStatusOportunidade;