import axios from "axios";
import {useState, useEffect} from 'react'
import {APIURL} from './config'


function Produtos(props) {

    const [results, setResults] = useState()

    useEffect(() => {
        listprodutos()
    }, [])

    const  listprodutos = async (e) => {
        
        try {
            const res = await axios.post(`${APIURL}/listprodutos.php`)
            if (res.data[0].ok) {
                setResults(res.data[0].conjuntofinal)
  
            } else {
                alert("Erro ao listar produtos! " + res.data[0].msg)
            }
        }
        catch(err){
            alert(err + " listar produtos")
        }
    }

    return (
        <select onChange={(e)=> props.selectproduto(e.target.value)} className="form-control">
            <option value="">Produto...</option>
            { results && results.map( (results) => <option value={results.produtoid}>{ results.nomeproduto }</option>) }
        </select>
    )

}
    
export default Produtos;