import axios from "axios";

import {useState, useEffect} from 'react'
import { useNavigate, useLocation, Link  } from 'react-router-dom';
import {APIURL} from './config'

import Navbar from './Navbar'
import Topbar from './Topbar'

function RevendaUnica() {

    const navigate = useNavigate()
    const locate = useLocation()

    const mycodigoempresa = locate.state.codigoempresa

    const [results, setResults] = useState([])
    const [nomeempresa, setNome] = useState()
    const [cnpj, setCNPJ] = useState()
    const [tipoempresa, setTipo] = useState()
    const [responsavel, setResponsavel] = useState()
    const [telefone, setTelefone] = useState()
    const [departamento, setDepartamento] = useState()
    const [email, setEmail] = useState()
    const [senha, setSenha] = useState()

    useEffect(() => {
        if (!sessionStorage.getItem("codigoempresa"))  {
            navigate('/login')
        }
        listresume()
    }, [])

    const listresume = async () => {
        
        try {
            const res = await axios.post(`${APIURL}/listrevendaunica.php`, {codigoempresa : mycodigoempresa});
           if (res.data[0].ok) {
               setResults(res.data[0].conjuntofinal[0]);
               setNome(res.data[0].conjuntofinal[0].nomeempresa)
               setCNPJ(res.data[0].conjuntofinal[0].cnpj)
               setResponsavel(res.data[0].conjuntofinal[0].nomeusuario)
               setTelefone(res.data[0].conjuntofinal[0].telefone)
               setDepartamento(res.data[0].conjuntofinal[0].departamento)
               setEmail(res.data[0].conjuntofinal[0].email)
               setSenha(res.data[0].conjuntofinal[0].senha)
            } else {
                alert("Erro nos dados da revenda")
            }
            
        } catch (err) {
            alert(err + " revenda única");
        }
    }  

    const  doSend = async (e) => {
        e.preventDefault()
        try {
            // alert(nomeempresa + cnpj + responsavel + email + telefone + departamento + senha )
            if (!nomeempresa || !cnpj || !responsavel || !email || !telefone || !departamento ) {
                alert("Faltam dados!")
                return
            }
            const res = await axios.post(`${APIURL}/alterarevendaunica.php`, {codigoempresa:  mycodigoempresa , nomeempresa : nomeempresa, cnpj: cnpj, responsavel: responsavel, email: email, telefone: telefone, departamento: departamento, senha: senha})
            if (res.data[0].ok) {
                alert("Alteração feita com sucesso!")
                navigate(-1)
            } else {
                if (res.data[0].msg == 3) {
                    alert("Erro ao alterar revenda!")
                }
            }
        }
        catch(err){
            alert(err + " altera revenda ")
        }

    }


  return (
    <div >
        <Navbar />
        <div >
            
            <div className="row border-bottom white-bg" style={{height: '100%', padding: 30, }}>
                <div className="container" >
                <div className="row" >
                <div className="col-12 ">
                    <h1 className="mb-4">{results && results.nomeempresa}</h1>
                        <div className="form-group">
                            <label>Nome da Revenda (*)</label> 
                            <input type="text"  className="form-control" value={nomeempresa} onChange={(e)=> setNome(e.target.value)}/>
                        </div>

                        <div className="form-group">
                            <label>CNPJ (*)</label> 
                            <input type="text"  className="form-control" value={cnpj} />
                        </div>


                        <div className="form-group">
                            <label>Nome do Responsável (*)</label> 
                            <input type="text"  className="form-control" value={responsavel}  onChange={(e)=> setResponsavel(e.target.value)}/>
                        </div>

                        <div className="form-group">
                            <label>Telefone (*)</label> 
                            <input type="text"  className="form-control" value={telefone}  onChange={(e)=> setTelefone(e.target.value)}/>
                        </div>

                        <div className="form-group">
                            <label>Departamento (*)</label> 
                            <input type="text"  className="form-control" value={departamento}  onChange={(e)=> setDepartamento(e.target.value)}/>
                        </div>

                        <div className="form-group">
                            <label>Email de Acesso (*)</label> 
                            <input type="text"  className="form-control" value={email} onChange={(e)=> setEmail(e.target.value)}/>
                        </div>

                        <div className="form-group">
                            <label>Senha de Acesso (*)</label> 
                            <input type="text"  className="form-control" value={senha}  onChange={(e)=> setSenha(e.target.value)}/>
                        </div>

                        <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'space-between', marginTop: 40}}>

                            <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                <button onClick={() => navigate(-1)} style={{marginRight: 20}} className="btn btn-lg  btn-outline pull-right" ><strong>Voltar</strong></button>
                                <button onClick={(e)=> doSend(e)} className="btn btn-lg fundoverde corbranco pull-right m-t-n-xs" type="submit"><strong>Alterar</strong></button>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default RevendaUnica;