import axios from "axios";

import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {APIURL} from './config'

import Navbar from './Navbar'
import Topbar from './Topbar'

function NovaRevenda() {

    const navigate = useNavigate()

    const [displaycampos, setDisplaycampos] = useState("none")

    const [nomeempresa, setNome] = useState()
    const [cnpj, setCNPJ] = useState()
    const [tiposeller, setTiposeller] = useState()
    const [responsavel, setResponsavel] = useState()
    const [telefone, setTelefone] = useState()
    const [departamento, setDepartamento] = useState()
    const [email, setEmail] = useState()
    const [senha, setSenha] = useState()

    useEffect(() => {
        if (!sessionStorage.getItem("codigoempresa"))  {
            navigate('/login')
        }
    }, [])

    const  pesquisarCNPJ = async (e) => {
        e.preventDefault()
        const mycnpj = cnpj.replace(/[^0-9]/g,'')
        if (mycnpj.length != 14) {
            alert("CNPJ precisa ter 14 dígitos!")
            return
        }
        setCNPJ(mycnpj)
        try {
            const res = await axios.post(`${APIURL}/getCNPJ.php`, {cnpj: mycnpj})
            if (res.data[0].ok) {
                //alert("Empresa não cadastrada!")
                setDisplaycampos("block")
            } else {
                if (res.data[0].msg == 2) {
                    if (res.data[0].seller ==1) {

                        alert("Empresa " + res.data[0].nomeempresa + " já cadastrada como Revenda!")
                        
                    } else {
                        if (res.data[0].cliente ==1) {
                            if (window.confirm("Empresa " + res.data[0].nomeempresa + " já cadastrada como Cliente! Quer cadastrá-la tambem como revenda?")) {
                                //marcacomorevenda()
                            }
                            alert("Empresa " + res.data[0].nomeempresa + " já cadastrada como Revenda!")

                        } else {
                            alert("Empresa cadastrada sem classificação")

                        }
                    }
                } else {
                    alert("Erro na pesquisa do CNPJ")
                }
            }
        }
        catch(err){
            alert(err)
        }

    }

    

    const  doSend = async (e) => {
        alert(nomeempresa + cnpj + tiposeller +responsavel +email + senha + telefone + departamento)
        e.preventDefault()
        try {

            const res = await axios.post(`${APIURL}/insertempresa.php`, {empresapaiid:  sessionStorage.getItem("codigoempresa") , nomeempresa : nomeempresa, cnpj: cnpj, tiposeller: tiposeller, responsavel: responsavel, emailacesso: email, senhaacesso: senha, telefone: telefone, departamento: departamento})
            if (res.data[0].ok) {
                navigate('/revendas')
            } else {
                alert("Erro ao inserir empresa!" + res.data[0].msg)
            }
        }
        catch(err){
            alert(err)
        }

    }


  return (
    <div >
        <Navbar />
        <div >
            <Topbar />
            <div className="white-bg" style={{height: '100%', padding: 30, }}>
                <div className="container" >
                    <div className="row  " >
                        <div className="col-12 mb-4" >
                            <h1>Nova Revenda</h1>
                        </div>

                        <div className="col-12" >
                        
                            <div className="form-group">
                                <label>CNPJ</label> 
                                <div className="d-flex justify-content-between">
                                    <input  type='text' className="form-control" onChange={(e)=> setCNPJ(e.target.value)} style={{marginRight: 20}}/>
                                    <button onClick={(e)=>pesquisarCNPJ(e)} className="btn btn-sm fundoverde corbranco pull-right m-t-n-xs" ><strong>Pesquisar</strong></button>
                                </div>
                            </div>
                            

                
                            <div style={{display: displaycampos}} >
                            
                                <div className="form-group">
                                    <label>Nome da Revenda</label> 
                                    <input type="text"  className="form-control" onChange={(e)=> setNome(e.target.value)}/>
                                </div>


                                <div className="form-group" > 
                                    <label>Tipo</label> 
                                    <select  onChange={(e)=> setTiposeller(e.target.value)} className="form-control">
                                        <option value="">Tipo...</option>
                                        {(sessionStorage.getItem("tiposeller") == 1)   &&
                                            <option value="2">Dealer</option>
                                        }
                                        <option value="3">Revenda</option>
                                        <option value="4">Finder</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>Nome do Responsável</label> 
                                    <input type="text"  className="form-control" onChange={(e)=> setResponsavel(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                    <label>Telefone</label> 
                                    <input type="text"  className="form-control" onChange={(e)=> setTelefone(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                    <label>Departamento</label> 
                                    <input type="text"  className="form-control" onChange={(e)=> setDepartamento(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                    <label>Email de Acesso</label> 
                                    <input type="text"  className="form-control" onChange={(e)=> setEmail(e.target.value)}/>
                                </div>

                                <div className="form-group">
                                    <label>Senha de Acesso</label> 
                                    <input type="text"  className="form-control" onChange={(e)=> setSenha(e.target.value)}/>
                                </div>

                                <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'space-between', marginTop: 80}}>

                                    <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                        <button style={{marginRight: 20}} onClick={() => navigate(-1)} className="btn btn-lg  btn-outline pull-right m-t-n-xs" ><strong>Voltar</strong></button>
                                        <button onClick={(e)=> doSend(e)} className="btn btn-lg fundoverde corbranco pull-right m-t-n-xs" type="submit"><strong>Salvar</strong></button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  );
}

export default NovaRevenda;