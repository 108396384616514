import axios from "axios";

import {useState, useEffect} from 'react'
import { Navigate, useNavigate,useLocation } from 'react-router-dom';
import {APIURL} from './config'

import Navbar from './Navbar'
import Topbar from './Topbar'
import Produtos from './Produtos'

function NovoProdutoOportunidade() {

    const navigate = useNavigate()
    const locate = useLocation()

    const mycodigooportunidade = locate.state.codigooportunidade

    const [produto, setProduto] = useState()
    const [quantidade, setQuantidade] = useState()
    const [precounitario, setPrecounitario] = useState(0)

    useEffect(() => {
        if (!sessionStorage.getItem("codigoempresa"))  {
            navigate('/login')
        }
    }, [])

       

    const  doSend = async (e) => {
        //alert(produto + precounitario + quantidade)
        e.preventDefault()
        if ( !produto || !quantidade) {
            alert("Faltam dados!")
            return
        }
        try {

            const res = await axios.post(`${APIURL}/insertprodutooportunidade.php`, {codigooportunidade:  mycodigooportunidade , produtoid: produto, quantidade: quantidade, precounitario: precounitario})
            if (res.data[0].ok) {
                navigate(-1)
            } else {
                alert("Erro ao inserir produto!" + res.data[0].msg)
            }
        }
        catch(err){
            alert(err)
        }

    }

    function gotoselectproduto(num) {
        setProduto(num)
    }


  return (
    <div >
        <Navbar />
        <div >
            <Topbar />
            <div className="white-bg" style={{height: '100%',  padding: 30, }}>
                <div className="container" >
                <   div className="row  " >
                    <div className="col-12 mb-4" >
                        <h1>Novo Produto</h1>
                    </div>

                    <div className="col-12">
                        
                        <div className="form-group" > 
                            <label>Produto (*)</label> 
                            <Produtos selectproduto={gotoselectproduto} />
                        </div>

                        <div className="form-group">
                                <label>Quantidade do Produto (*)</label> 
                                <input type="text"  className="form-control" onChange={(e)=> setQuantidade(e.target.value)}/>
                        </div>

                        <div className="form-group">
                                <label>Preço Unitário do Produto</label> 
                                <input type="text" className="form-control" onChange={(e)=> setPrecounitario(e.target.value)}/>
                        </div>

                        <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'space-between', marginTop: 80}}>

                            <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                <button style={{marginRight: 20}} onClick={() => navigate(-1)} className="btn btn-lg  btn-outline pull-right m-t-n-xs" ><strong>Voltar</strong></button>
                                <button onClick={(e)=> doSend(e)} className="btn btn-lg fundoverde corbranco pull-right m-t-n-xs" type="submit"><strong>Salvar</strong></button>
                            </div>
                            
                        </div>
                    </div>
                </div>

                    


                </div>
            </div>
        </div>
    </div>
  );
}

export default NovoProdutoOportunidade;