import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, Route, Navigate } from 'react-router-dom';

import App from './App';
import Login from './Login'
//import Register from ‘./Register’
import Home from './Home'
import Logout from './Logout'
import NovaRevenda from './NovaRevenda'
import Revendas from './Revendas'
import NovaOportunidade from './NovaOportunidade'
import MudancaStatus from './MudancaStatus'
import Oportunidades from './Oportunidades'
import RevendaUnica from './RevendaUnica'
import OportunidadeUnica from './OportunidadeUnica'
import NovoProdutoOportunidade from './NovoProdutoOportunidade'
import NovoStatusOportunidade from './NovoStatusOportunidade'
import Apoio from './Apoio'

const myrouter = createBrowserRouter(
  [
    {
      element: <App />,
      children: [
        {
          path: "/",
          element: <Login />
        },
        {
          path: "/login",
          element: <Login />
        },
        {
          path: "/home",
          element: <Home />
        },
        {
          path: "/logout",
          element: <Logout />
        },
        {
          path: "/novarevenda",
          element: <NovaRevenda />
        },
        {
          path: "/revendas",
          element: <Revendas />
        },
        {
          path: "/novaoportunidade",
          element: <NovaOportunidade />
        },
        {
          path: "/mudancastatus",
          element: <MudancaStatus />
        },
        {
          path: "/oportunidades",
          element: <Oportunidades />
        },
        {
          path: "/revendaunica",
          element: <RevendaUnica />
        },
        {
          path: "/oportunidadeunica",
          element: <OportunidadeUnica />
        },
        {
          path: "/novoprodutooportunidade",
          element: <NovoProdutoOportunidade />
        },
        {
          path: "/novostatusoportunidade",
          element: <NovoStatusOportunidade />
        },
        {
          path: "/apoio",
          element: <Apoio />
        },


	    ]
    }
  ]
)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={myrouter} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
