import logo from './img/logo-4vendor.png'
import fundo from './img/fundo-login.png'
import axios from "axios";

import {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import {APIURL} from './config'

function Login() {

    const navigate = useNavigate()

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const  doLogin = async (e) => {
        e.preventDefault()
        if (!email || !password) {
            alert("Faltam dados!")
            return
        }
        try {
            const res = await axios.post(`${APIURL}/getlogin.php`, {Email: email, Senha: password})
            //alert(res.data[0].conjuntofinal[0].nomeusuario)

            if (res.data[0].ok) {
                sessionStorage.setItem("nomeusuario", res.data[0].conjuntofinal[0].nomeusuario);
                sessionStorage.setItem("codigoempresa", res.data[0].conjuntofinal[0].empresaid);
                sessionStorage.setItem("codigousuario", res.data[0].conjuntofinal[0].usuarioid);
                sessionStorage.setItem("nomeempresa", res.data[0].conjuntofinal[0].nomeempresa);
                sessionStorage.setItem("tiposeller", res.data[0].conjuntofinal[0].tiposeller);
                navigate('/home')
                //alert("Login feito")
            } else {
                alert("Dados incorretos.Tente novamente")
            }
            
        }
        catch(err){
            alert("Erro" + err)
        }

    }


  return (
    <div>
        {/* <div className="row "   >
            <div className="col-md-12 " style={{display:"flex", justifyContent: "center", alignItems:"center", backgroundColor: "red", height: "100vh"}}>
                
                    <p>Hello</p>
               
             </div>
        </div> */}
    <div className="d-flex align-items-center justify-content-center" style={{backgroundColor: "#e3e3e3", height: '100vh',  }}>
        <div className="container "  >
            <div className="row p-5"  >
                <div className="col-md-1 d-none d-sm-block">
                </div>

                <div className="col-xs-12 col-md-5 d-flex align-items-center justify-content-center" style={{backgroundColor: "#f4f4f4",padding:"30px", height:"80vh"}}>
                    <div className="text-center">
                        <img src={logo} alt="logo" className="img-fluid" style={{height:120, width:120,}} />

                        <h1 style={{marginBottom: 40}}>Bem-vindo ao 4Vendor</h1>
                        
                        
                        <form onSubmit={(e)=> doLogin(e)} className="m-t" >
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Email" required="" onChange={(e)=> setEmail(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Senha" required="" onChange={(e)=> setPassword(e.target.value)} />
                            </div>
                            <button type="submit" className="btn fundoverde corbranco block full-width m-b">LOGIN</button>

                            <p>Esqueceu a senha?</p>
                            
                            <Link to="/register"><button className="btn btn-sm fundoverde corbranco btn-outline btn-block" >Não tem uma conta? Crie agora!</button></Link>
                        </form>
                    </div>
                    
                </div>

                <div className="col-md-5 d-none d-md-block align-content-center " style={{backgroundColor: "white", height:"80vh" }}>

                        <img src={fundo} alt="logo" className="img-fluid" style={{height:"60vh",objectFit: "cover"}} />
                  
                </div>

                <div className="col-md-1 d-none d-sm-block">
                </div>
            </div>
        </div>
    </div>
    </div>            
  );
}

export default Login;